import React, { useEffect, useState } from "react";

import { BsArrowRight } from "react-icons/bs";


import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";


import ProjectBtn from "../components/ProjectBtn";
import ProjectCard from "../components/ProjectCard";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ProjectsData from '../models/projects.json';
import projectsDataAR from '../models/projectsAr.json';


const Projects = () => {
  const [t, i18n] = useTranslation();
  const [item, setItem] = useState([]);      // items
  const [allData, setAllData] = useState([]);    ///api data 
  // const [item, setItem] = useState(i18n.language === "en-US" ? ProjectsData : projectsDataAR);      // items
  const [video, setVideo] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const lang = localStorage.i18nextLng === "en-US" ? 1 : 2;    //language
  // console.log("item", item);

  const [menuItems, setMenuItems] = useState([]);



  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://api.udtech-sa.com/api/WebSite/GetProjects?languageId=${lang}`
  //     )
  //     .then((res) => {
  //       setApiData(res.data);

  //       if(i18n.language === 'en-US')
  //       {setItem( res.data.filter((newVal) => {
  //         return newVal.category === 'Websites';
  //       }
  //       ));}
  //       else if (i18n.language === 'ar')
  //       {setItem( res.data.filter((newVal) => {
  //         return newVal.category === 'مواقع الكترونية';
  //       }
  //       ));}
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [lang]);

  // const [ testData , setTestDate] = useState(data);

  useEffect(() => {
    axios
      .get("https://api.udtech-sa.com/api/WebSite/GetPagesVideo")
      .then((res) => {
        setVideo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const menuItemsData = [...new Set(item.map((Val) => Val.title))];


  // const [menuItems, setMenuItems]= useState([menuItemsData]);
  // console.log("menu" , menuItemsData);



  useEffect(() => {
    const currentData = localStorage.i18nextLng === "en-US" ? ProjectsData : projectsDataAR;
    // console.log("currentData", currentData);

    setItem(currentData);
    setAllData(currentData);

    const menuItemTitles = [...new Set(currentData.map((val) => val.title))];
    setMenuItems(menuItemTitles);
    // console.log("menuItemTitles" , menuItemTitles);

    // setMenuItems(currentData.map((val)=> val.title));

  }, [localStorage.i18nextLng]);



  // console.log("menItems", menuItems);

  // const filterItem = (curcat) => {
  //   console.log("curcat" , curcat);

  //   const newItem = ProjectsData.filter((newVal) => {
  //     return newVal.title === curcat;
  //   });
  //   console.log("newItem", newItem);
  //   console.log("item", item );

  //   setItem(newItem);
  //   console.log("set item" , setItem);

  // };

  const filterItem = (curcet) => {
    console.log('curcet', curcet);
    // const newItem  = item.filter((val) => val.title === curcet );
    // console.log('newVal' , newItem);
    // console.log('item from filter' , item);

    // setItem(newItem);

    if (curcet === "All") {
      setItem(allData);
      console.log(1);
      
    } else {
      console.log(2);
      
      let filteredData = allData.filter((val) => val.title === curcet);
      // console.log("filteredData" , filteredData);
      setItem(filteredData);
    }
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  });
  const [preLoader, setPreLoader] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setPreLoader(false);
    }, 1000);
  }, []);

  return (
    <>
      <div>
        {preLoader && (
          <div className="preloader">
            <div className="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <main className="creasoft-wrap">
          <div className="line_wrap">
            <div className="line_item"></div>
            <div className="line_item"></div>
            <div className="line_item"></div>
            <div className="line_item"></div>
            <div className="line_item"></div>
          </div>
          <section className="breadcrumbs project-header">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb-wrapper">
                    <div className="breadcrumb-cnt">
                      <h1>{t("our_projects")}</h1>
                      <span>
                        <a href={`/${i18n.language}`}>{t("home")}</a>
                        <BsArrowRight className="m-1" />
                        {t("our_projects")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body>
              <video
                src={video.video_Projects}
                controls
                autoPlay
                className="bread-video"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setShowModal(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
          <section className="projectsPage project-area sec-mar pb-5">
            <div className="container">
              <div className="title-wrap">
                <div className="sec-title ProjectSec">
                  <h3 className="n-h3">
                    <span className=""></span>
                    {t("our_projects")}
                  </h3>
                  <span>{t("project_page_case_study")}</span>

                  <p>{t("project_page_paragraph")}</p>
                </div>
              </div>
              <ProjectBtn
                setItem={setItem}
                menuItems={menuItems}
                // menuItems={menuItemsData}
                filterItem={filterItem}
                // item={ProjectsData}
                item={allData}
              />
              {/* <ProjectCard item={item} /> */}
              <ProjectCard item={item} />
            </div>
          </section>
          {/*<Newsletter /> */}
        </main>
      </div>
    </>
  );
};

export default Projects;
