import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const ProjectBtn = ({ setItem, menuItems, filterItem, item }) => {

  // console.log("menuItemsss" , filterItem);

  const [t, i18n] = useTranslation();
  const actVal = i18n.language === "en-US" ? 'All' : 'الكل';

  const [active, setActive] = useState(actVal);

  // console.log("actVa", actVal, i18n.language)

  const filterFunction = (Val) => {
    // console.log("val", Val);
    filterItem(Val);
    setActive(Val);
  };
  const AllFilterFunction = (Val) => {
    console.log("val from allfilter", Val);

    setItem(item);
    setActive("All");
  };

  return (
    <div className="d-flex justify-content-center">
      <ul className="isotope-menu layout2 d-md-block d-none">
        <li
          className={actVal === "All" ? active === "All" && "active" : active === "الكل" && "active"}
          onClick={() => AllFilterFunction()}
        >

          {t("All")}
        </li>
        {menuItems.sort((a, b) => a?.localeCompare(b)).map((Val, id) => {
          return (
            <li
              onClick={() => filterFunction(Val)}
              key={Val.id}
              className={active === Val && "active"}
            >
              {Val}
            </li>
          );
        })}
      </ul>





      <div class="dropdown  d-md-none d-block ">
        <a class=" btn dropdown-toggle   isotope-menu custom-dropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
         {t ("projects_all")}
        </a>
        <ul class="dropdown-menu isotope-menu custom-menuList layout2">
          <li
            className={actVal === "All" ? active === "All" && "active" : active === "الكل" && "active"}
            onClick={() => AllFilterFunction()}
          >

            {t("All")}
          </li>
          {menuItems.sort((a, b) => a?.localeCompare(b)).map((Val, id) => {
            return (
              <li
                onClick={() => filterFunction(Val)}
                key={Val.id}
                className={active === Val && "active"}
              >
                {Val}
              </li>
            );
          })}
        </ul>
      </div>
    </div>

  );
};

export default ProjectBtn;
