import React, { useEffect } from "react";
import WOW from "wowjs";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ProjectCard = ({ item }) => {
  // console.log('item' , item);
  const [t, i18n] = useTranslation();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  });

  return (
    <section className="project-card">
      <div className="container-fluid main-card">
        <div className="row g-4 project-items">
          {item.map((Val) => {
            return (
              // <div
              //   className="col-6 col-md-6 col-lg-4 single-item d-flex justify-content-center align-items-center wow animate animate__fadeInUp "
              //   data-wow-duration="500ms"
              //   key={Val.id}
              // >
              <div
                className="col-6 col-md-6 col-lg-4 single-item   wow animate animate__fadeInUp "
                data-wow-duration="500ms"
                key={Val.id}
              >
                <div className="item-img hand-cursor">
                  <a href={`/${i18n.language}/${t("project-details")}/${Val.id}`}>
                    {/* <img className="maxH-300" src={Val.photoPath} alt={Val.title} /> */}
                    {/* <img  src={mayar} alt={Val.title}  /> */}
                    <img  src={ Val.projectImage} alt={Val.title}  />
                  </a>
                </div>
                <a href={`/${i18n.language}/${t("project-details")}/${Val.id}`} className="cursorP">
                  <div className="item-inner-cnt">
                      <h6>{Val.title}</h6>
                    {/* <h4>{Val.projectName}</h4> */}
                    <h4>{Val.projectName.length > 15 ? `${Val.projectName.substring(0,15)}...` : `${Val.projectName}`
                      
                      }</h4>

                    {/*<span>{Val.title}</span>
                    <h4>{Val.project}</h4>
                    <div className="view-btn">
                      <a href={`/project-details/${Val.id}`}>
                        {t("view_details")}
                      </a>
                    </div>*/}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProjectCard;
